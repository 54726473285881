<template>
  <div>
    <!-- 头部 -->
    <section class="section panel contact_header">
      <div class="col-lg-12 container_max">
        <div class="T48px_Regular">
          <span class="colorfff">{{ $t("contact.header_title1") }}</span>
          <span class="color1D73FF">{{ $t("contact.header_title2") }}</span>
        </div>

        <span class="T16px_Regular tips_text">
          {{ $t("contact.header_tips") }}
        </span>

        <div class="form_box">
          <header class="form_header_box T24px_Regular">
            <span class="colorfff"> {{ $t("contact.form_header_box1") }}</span>
            <span class="color1D73FF">
              {{ $t("contact.form_header_box2") }}
            </span>
          </header>

          <div class="col-12 flex-wb">
            <div class="col-6 flex-c">
              <span class="T14px_Regular colorfff">
                {{ $t("contact.name_input") }}
              </span>
              <input
                type="text"
                :placeholder="$t('contact.name_inputs')"
                v-model="form.name"
                class="input_css T12px_Regular"
              />
            </div>
            <div class="col-6 flex-c">
              <span class="T14px_Regular colorfff">
                {{ $t("contact.email_input") }}
              </span>
              <input
                type="text"
                class="input_css T12px_Regular"
                v-model="form.email"
                placeholder="Bonjour@company.com"
              />
            </div>
          </div>
          <div class="col-12 flex-wb">
            <div class="col-6 flex-c">
              <span class="T14px_Regular colorfff">
                {{ $t("contact.type_input") }}
              </span>
              <select
                class="select_css T12px_Regular"
                v-model="form.type"
                :placeholder="`${$t('contact.type_input_1')}...`"
              >
                <option value="">{{ $t("contact.type_input_1") }}</option>
                <option value="1">{{ $t("contact.type_input_2") }}</option>
                <option value="2">{{ $t("contact.type_input_3") }}</option>
                <option value="3">{{ $t("contact.type_input_4") }}</option>
                <option value="4">{{ $t("contact.type_input_5") }}</option>
              </select>
            </div>
          </div>
          <div class="col-12 flex-wb">
            <div class="col-12 flex-c">
              <span class="T14px_Regular colorfff">
                {{ $t("contact.messages_input") }}
              </span>
              <textarea
                v-model="form.messages"
                class="textarea_css T12px_Regular"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="col-12 flex-wb">
            <button @click="SubmitForm" class="submit_form T18px_Regular">
              {{ $t("contact.SubmitForm") }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {
        type: "",
      },
    };
  },

  methods: {
    /***
     * 提交联系我们邮箱联系方式
     */

    SubmitForm() {
      let type = this.form.type;
      let consultingArr = [];
      if (type == 1) {
        consultingArr.push("软件定制开发");
      } else if (type == 2) {
        consultingArr.push("网站定制开发");
      } else if (type == 1) {
        consultingArr.push("品牌设计");
      } else if (type == 1) {
        consultingArr.push("一站式搭建");
      }
      let message = `名称：${this.form.name}邮箱：${
        this.form.email
      }产品:${consultingArr.toString(",")}需求:${this.form.messages}`;
      this.form.content = message;
      this.form.addr = "1915338494@qq.com";
      let that = this;

      const params = new URLSearchParams(); // 创建对象
      params.append("content", message); // 封装对象
      params.append("addr", "1915338494@qq.com"); // 封装对象

      axios({
        method: "post",
        url: "https://api.goblackwave.com/pub-api/rest/v1/user/mail/send",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          "tenant-id": "1",
        },
        data: params,
      }).then(
        () => {
          that.form = {};
          this.songShow = true;
          this.$message({
            message: this.$t("mesg"),
            type: "success",
          });
          setTimeout(() => {
            this.songShow = false;
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.contact_header {
  min-height: 100vh;
  background: #15171d;
  padding-top: 5.6875rem;

  .container_max {
    width: 100%;
    padding: 0 5.875rem;

    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tips_text {
      width: 30.0313rem;
      line-height: 1.3125rem;
      color: #bdbebf;
    }

    .form_box {
      margin-top: 1.8125rem;
      width: 100%;
      // height: calc((22.375rem - 2.5rem) - 2.9688rem);
      background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/contact/contact_bj.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;

      padding: 2.5rem 3.125rem 3.9688rem 3.0313rem;

      .form_header_box {
        margin-bottom: 1.25rem;
      }

      .input_css {
        width: 16.75rem;
        height: 1.25rem;
        border: 0.0313rem solid rgba(128, 128, 128, 0.55);
        background: transparent;
        text-align: center;
        margin-top: 0.25rem;
        color: #fff;
        margin-bottom: 1.0938rem;
      }

      .select_css {
        width: 16.75rem;
        height: 1.25rem;
        border: 0.0313rem solid rgba(128, 128, 128, 0.55);
        background: transparent;
        text-align: center;
        margin-top: 0.25rem;
        color: #fff;
        margin-bottom: 1.0938rem;
      }

      .textarea_css {
        width: 100%;
        height: 7.0625rem;
        border: 0.0313rem solid rgba(128, 128, 128, 0.55);
        background: transparent;
        margin-top: 0.25rem;
        color: #fff;
      }
    }
  }
}

option {
  color: #000;
}

.submit_form {
  width: 100%;
  height: 2.4063rem;
  border-radius: 0px 0px 0px 0px;
  border: 0.0313rem solid rgba(128, 128, 128, 0.55);

  padding: 0;
  margin: 0;
  margin-top: 1.5rem;
  background: transparent;
  color: #fff;
}
</style>
